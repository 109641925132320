<template>
    <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.625 1.26953V5.40007C11.625 5.96012 11.625 6.24015 11.734 6.45406C11.8299 6.64222 11.9828 6.7952 12.171 6.89108C12.3849 7.00007 12.6649 7.00007 13.225 7.00007H17.3555M13.625 12H5.625M13.625 16H5.625M7.625 8H5.625M11.625 1H6.425C4.74484 1 3.90476 1 3.26303 1.32698C2.69854 1.6146 2.2396 2.07354 1.95198 2.63803C1.625 3.27976 1.625 4.11984 1.625 5.8V16.2C1.625 17.8802 1.625 18.7202 1.95198 19.362C2.2396 19.9265 2.69854 20.3854 3.26303 20.673C3.90476 21 4.74484 21 6.425 21H12.825C14.5052 21 15.3452 21 15.987 20.673C16.5515 20.3854 17.0104 19.9265 17.298 19.362C17.625 18.7202 17.625 17.8802 17.625 16.2V7L11.625 1Z"
            stroke="#FF690C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Reviews'
}
</script>
<style scoped lang='scss'>
svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
