<template>
    <svg
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.375 8L7.375 8M19.375 2L7.375 2M19.375 14L7.375 14M3.375 8C3.375 8.55228 2.92728 9 2.375 9C1.82272 9 1.375 8.55228 1.375 8C1.375 7.44772 1.82272 7 2.375 7C2.92728 7 3.375 7.44772 3.375 8ZM3.375 2C3.375 2.55228 2.92728 3 2.375 3C1.82272 3 1.375 2.55228 1.375 2C1.375 1.44772 1.82272 1 2.375 1C2.92728 1 3.375 1.44772 3.375 2ZM3.375 14C3.375 14.5523 2.92728 15 2.375 15C1.82272 15 1.375 14.5523 1.375 14C1.375 13.4477 1.82272 13 2.375 13C2.92728 13 3.375 13.4477 3.375 14Z"
            stroke="#FF690C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'AboutWriter'
}
</script>
<style scoped lang='scss'>
svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
