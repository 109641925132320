<template>
    <nav>
        <ul class="orders-details-tabs__tab-links">
            <template v-for="item in items">
                <li
                    v-if="!item.hide"
                    :key="item.value"
                    class="button transparent-btn"
                    :class="{'active-tab-link': $route.name === item.value}"
                    @click.prevent="redirectToTab(item.value)"
                >
                    <component :is="item.icon" />
                    <span class="order-tab-text">
                        {{ item.text }}
                    </span>
                    <span
                        v-if="item.counter || item.counter === 0"
                        class="order-tab-counter"
                    >
                        {{ item.counter }}
                        <span
                            v-if="item.counterUndread > 0"
                            class="bubble--unread bubble--unread-small"
                        >
                            {{ item.counterUndread }}
                        </span>
                    </span>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script>

import List from '@/components/icons/List'
import PaperClip from '@/components/icons/PaperClip'
import Mail from '@/components/icons/Mail'
import Support from '@/components/icons/Support'

export default {
    name: 'OrderDetailsTabs',
    components: {
        List,
        PaperClip,
        Mail,
        Support
    },
    props: {
        items: {
            type: Array
        }
    },
    methods: {
        redirectToTab(value) {
            this.$emit('redirectToTab', value)
        }
    }
}
</script>

<style lang="scss" scoped>
    .orders-details-tabs__tab-links {
        @media screen and (max-width: 992px) {
            display: none ;
        }
    }
</style>
